import React from "react";
// import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

const Menus = () => {
  const { hash } = useLocation();
  const isActive = (iHash) => hash === iHash;
  const theme={
    color:"#662F85",
    textDecoration: "underline",
    fontWeight: "bold",
    // fontSize:"1.1em",
  }
  return (
    <ul>
      <li>
        <HashLink to="#home" data-to-scrollspy-id="home"
        // style={
        //   isActive("#home")
        //     ? {
        //         ...theme
        //       }
        //     : {}
        // }
        >Home</HashLink>
        {/* <ul className="submenu">
          <li>
            <Link to="/">Home Style 1</Link>
          </li>
          <li>
            <Link to="/home-2">Home Style 2</Link>
          </li>
          <li>
            <Link to="/home-3">Home Style 3</Link>
          </li>
        </ul> */}
      </li>
      <li>
      <HashLink to="/#about" data-to-scrollspy-id="about" 
      // style={
      //     isActive("#about")
      //       ? {
      //           ...theme
      //         }
      //       : {}
      //   }
        >Why Us</HashLink>
      </li>
      <li>
        <HashLink to="/#whocan"  data-to-scrollspy-id="whocan"
        // style={
        //   isActive("#whocan")
        //     ? {
        //         ...theme
        //       }
        //     : {}
        // }
        >Admission</HashLink>
      </li>
      <li>
        <HashLink to="/#course" data-to-scrollspy-id="course"
        // style={
        //   isActive("#course")
        //     ? {
        //         ...theme
        //       }
        //     : {}
        // }
        >Courses</HashLink>
      </li>
      <li>
        <HashLink to="/#testimonial" data-to-scrollspy-id="testimonial"
        // style={
        //   isActive("#testimonial")
        //     ? {
        //         ...theme
        //       }
        //     : {}
        // }
        >LIFE@CORE</HashLink>
      </li>
      <li>
        <HashLink to="/#contact" data-to-scrollspy-id="contact"
        // style={
        //   isActive("#contact")
        //     ? {
        //         ...theme
        //       }
        //     : {}
        // }
        >Contact</HashLink>
      </li>
      {/* <li className="has-dropdown">
        <Link to="/team">Pages</Link>
        <ul className="submenu">
          <li>
            <Link to="/team">Team</Link>
          </li>
          <li>
            <Link to="/team-details">Team details</Link>
          </li>
          <li>
            <Link to="/service">Service</Link>
          </li>
          <li>
            <Link to="/service-details">service details</Link>
          </li>
          <li>
            <Link to="/price">Price</Link>
          </li>
          <li>
            <Link to="/contact">contact</Link>
          </li>
          <li>
            <Link to="/faq">Faq</Link>
          </li>
        </ul>
      </li>
      <li className="has-dropdown">
        <Link to="/portfolio">Portfolio</Link>
        <ul className="submenu">
          <li>
            <Link to="/portfolio">Portfolio</Link>
          </li>
          <li>
            <Link to="/portfolio-details">Portfolio Details</Link>
          </li>
        </ul>
      </li>
      <li className="has-dropdown">
        <Link to="/blog">Blog</Link>
        <ul className="submenu">
          <li>
            <Link to="/blog">blog</Link>
          </li>
          <li>
            <Link to="/blog-details">blog Details</Link>
          </li>
        </ul>
      </li> */}
    </ul>
  );
};

export default Menus;
