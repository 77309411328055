import React from "react";
// import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';

// portfolio items
const portfolio_data = [
  {
    id:1,
    img:'/assets/img/bg/interior1.jpeg',
    tag:"Design",
    title:"Interior Design",
    link:"/interior"
  },
  {
    id:2,
    img:'/assets/img/bg/fashion1.jpeg',
    tag:"Design",
    title:"Fashion Design",
    link:"/fashion"
  },
  {
    id:3,
    img:'/assets/img/bg/event1.jpeg',
    tag:"Design",
    title:"Event Management",
    link:"/event"
  },
  {
    id:4,
    img:'/assets/img/bg/software1.jpeg',
    tag:"Computer Software",
    title:"Software and Animation",
    link:"/software"
  },
]

const PortfolioItems = () => {
 const  switchCase=(id)=>{
    switch(id) {
      case 1:
        onOpenModal1();
        break;
      case 2:
        onOpenModal2();
        break;
      case 3:
        onOpenModal3();
        break;
      case 4:
        onOpenModal4();
        break;
      default:
        return 'foo';
    }
  };
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [open4, setOpen4] = React.useState(false);
  const onOpenModal1 = () => setOpen1(true);
  const onCloseModal1 = () => setOpen1(false);
  const onOpenModal2 = () => setOpen2(true);
  const onCloseModal2 = () => setOpen2(false);
  const onOpenModal3 = () => setOpen3(true);
  const onCloseModal3 = () => setOpen3(false);
  const onOpenModal4 = () => setOpen4(true);
  const onCloseModal4 = () => setOpen4(false);
  
  return (
    <>
    <Modal open={open1} onClose={onCloseModal1} center classNames={{modal:'customModal'}}>
    <div className="team-main-content pt-40 pb-40">
        <div className="container">
        <div className="row">
            <div className="col-12">
              <div className="tp-section-wrapper text-center mb-30">
                {/* <span className="tp-section-subtitle mb-25">WHY US</span> */}
                <h2 className="tp-section-title" style={{textShadow: ".05em 0 0 rgba(179, 132, 201, .84)"}}>Interior Design</h2>
              </div>
            </div>
          </div>
          <div className="team-page-content pr-40">
            <div className="row">
              <p className="mb-25">
                    One the top institutes for Interior Designing Courses in Pune, Core Institute is committed to provide
                    the best learning platform to get to it's students to their dream careers. With contemporary
                    knowledge taught by experts in the field, software trainings, live site projects, internships and
                    workshops, students become confident, industry-ready and highly employable.
                  </p>
              <div className="col-lg-12 col-sm-12">
                <div className="team-page-text">
                  {/* <h3 className="team-title-text mb-25">About Hilextina</h3> */}
                  

                  <div style={{backgroundColor:"#662F85"}}><h4 style={{color:"white"}}> <strong>Government Certified Diploma Course</strong></h4></div>
                  <strong><h4 style={{color:"#662F85"}}>ONE YEAR CERTIFICATE COURSE</h4></strong>
                    <p>Art of Drawing skills, Art & Graphics, Building
                        Construction, Art & Furniture Design, History,
                        Material, Art of Studio Design, Estimating &
                        Costing, Market survey, Work shops, Commercial
                        Site visits, Software, Special Highlights.</p>
                  <strong><h4 style={{color:"#662F85"}}>TWO YEARS ADVANCED DIPLOMA</h4></strong>
                  <p>Advanced Building Construction, Design Idea,
                      Material & Servicing, Life Space Planning, Model
                      Making, Art of Studio Design Estimating &
                      Costing, History, Market Survey, Workshops,
                      Commercial Site Visits, Software, Special
                      Highlights.</p>
                <strong><h4 style={{color:"#662F85"}}>Six Months Course</h4></strong>
                  <p>Basic Design, Interior Design (Residential),
                    Interior Construction, Services, Theory of
                    Materials, CAD, Graphic Design, Site Visit</p>
                </div>
                <div style={{backgroundColor:"#662F85"}}><h4 style={{color:"white"}}> <strong>Government Certified Degree Course</strong></h4></div>
                  <strong><h4 style={{color:"#662F85"}}>THREE YEAR DEGREE COURSE (B.Sc. ID)</h4></strong>
                    <p> All Diploma curriculum plus advanced Software
                        courses, Management Skills, Professional
                        Practices, Thesis Project, Training, Interior &
                        Exterior Treatments, Landscape Environmental
                        Control, Special Highlights, Industrial Interior
                        Designing and internship.
                    </p>
                </div>
            </div>
          </div>
          
        </div>
      </div>
      </Modal>
      <Modal open={open2} onClose={onCloseModal2} center classNames={{modal:'customModal'}}>
      <div className="team-main-content pt-40 pb-40">
        <div className="container">
        <div className="row">
            <div className="col-12">
              <div className="tp-section-wrapper text-center mb-30">
                {/* <span className="tp-section-subtitle mb-25">WHY US</span> */}
                <h2 className="tp-section-title" style={{textShadow: ".05em 0 0 rgba(179, 132, 201, .84)"}}>Fashion Design</h2>
              </div>
            </div>
          </div>
          <div className="team-page-content pr-40">
          <p className="mb-25">
                    Fuel your passion for fashion by enrolling in fashion designing (FD) courses at Core Institute: your
                    gateway to the fashion world. Core Institute specializes in creating promising careers in the fashion
                    industry, drawing on it's years of experience and hand-on learning approach. This course equips
                    Students with the skills necessary to identify top-notch master tailors, pattern makers, and simple makers.
                    FD course will cover eveything from sourcing fabrics and suppliers to establishing your very own boutique.
                    Embark on a journey in the fashion industry with Core Institute's Fashion designing course.
                  </p>
            <div className="row">
              <div className="col-lg-12 col-sm-12">
                <div className="team-page-text">
                  {/* <h3 className="team-title-text mb-25">About Hilextina</h3> */}
                  
                  <div style={{backgroundColor:"#662F85"}}><h4 style={{color:"white"}}> <strong>THREE YEAR PROF, ADV, DIPLOMA</strong></h4></div>
                  {/* <strong><h4 style={{color:"#662F85"}}>ONE YEAR CERTIFICATE COURSE</h4></strong> */}
                    <p>Advanced pattern making, grading, advanced garment construction, advance fashion illustration,
                       portfolio, advance embriodery, accessory design, photography workshop, fabric formation and
                       processing, research craft documentation make up, styling, portfolio making, Guest Faculty
                       Lectures, Fashon CAD, Designer Studio Visit, Professional Fashion Management and Advance Fashion Styling.
                    </p>
                </div>
                <div style={{backgroundColor:"#662F85"}}><h4 style={{color:"white"}}> <strong>ONE YEAR DIPLOMA COURSE</strong></h4></div>
                    <p>Basic Design & Design idea illustration, emriodery, pattern making, garment constructions,
                       textiles, fashion CAD, Range Development, Workshops, Industrial Visit, Portfolio,
                       Fashion Marketing Management and Fashion Styling.</p>
                       <div style={{backgroundColor:"#662F85"}}><h4 style={{color:"white"}}> <strong>TWO YEAR ADVANCED DIPLOMA COURSE</strong></h4></div>
                    <p>Design idea & illustration, Embriodery, Pattern making, Garment constructions, textiles, history of fashion, fashion CAD,
                       Range development, workshops, Guest Faculty Lectures, Industrial + Design Studio Visits, Designer PPT, Fashion Merchandising,
                       portfolio, advance fashion marketing management, advance styling and fashion communication.
                    </p>
                </div>
              </div>
          </div>
        </div>
      </div>
      </Modal>
      <Modal open={open3} onClose={onCloseModal3} center classNames={{modal:'customModal'}}>
      <div className="team-main-content pt-40 pb-40">
        <div className="container">
        <div className="row">
            <div className="col-12">
              <div className="tp-section-wrapper text-center mb-30">
                {/* <span className="tp-section-subtitle mb-25">WHY US</span> */}
                <h2 className="tp-section-title" style={{textShadow: ".05em 0 0 rgba(179, 132, 201, .84)"}}>Event Management</h2>
              </div>
            </div>
          </div>
          <div className="team-page-content pr-40">
          <p className="mb-25">
                  Turn your event management passion into a high-paying career with practical trainings and
                  Workshop oriented learning at Core Institute. Learn the technicalities to plan, promote, and
                  execute events like weddings, Music concerts and more. Enhance your creativity, networking,
                  people management, and communication skills to become a successful event manager. Our
                  curriculum covers everything from visualizing events to adhering to budgets and legalities.
                  Join now and become the driving force behind successful events!
                  </p>
            <div className="row">
              <div className="col-lg-12 col-sm-12">
                <div className="team-page-text">
                  {/* <h3 className="team-title-text mb-25">About Hilextina</h3> */}
                  
                  <div style={{backgroundColor:"#662F85"}}><h4 style={{color:"white"}}> <strong>ONE YEAR ADVANCE CERTIFICATION</strong></h4></div>
                        <div className="row">
                        <div className="col-lg-6" >
                          <ul style={{listStyleType:'disc'}}>
                            <li>✔ Communication & Presentation Skills</li>
                            <li>✔ Event Concept & Creative Collaterals</li>
                            <li>✔ Set Design & Venue Costrctions</li>
                            <li>✔ Event Operations & Productions</li>
                            <li>✔ Sponsorship</li>
                            <li>✔ Celebrity Artist Management</li>
                            <li>✔ Corporate Event</li>
                          </ul>
                        </div>
                        <div className="col-lg-6">
                          <ul>
                            <li>✔ Wedding Event</li>
                            <li>✔ Personal Celebration</li>
                            <li>✔ Birthday Party, Anniversaries, Name Ceremony</li>
                            <li>✔ Exhibition & Trade Fairs</li>
                            <li>✔ Music Events & Concerts</li>
                            <li>✔ Political Event Management</li>
                            <li>✔ Anchoring</li>
                          </ul>
                        </div>
                        </div> 
                </div>
                <div style={{backgroundColor:"#662F85"}}><h4 style={{color:"white"}}> <strong>SIX MONTH CERTIFICATION</strong></h4></div>
                <div className="row">
                         <ul>
                            <li>✔ Communication & Presentation Skills</li>
                            <li>✔ Event Concept & Creative Collaterals</li>
                            <li>✔ Set Design & Venue Construction</li>
                            <li>✔ Weddings Events</li>
                            <li>✔ Personal Celebration</li>
                          </ul>
                        </div> 
                </div>
            </div>
          </div>
        </div>
      </div>
      </Modal>
      <Modal open={open4} onClose={onCloseModal4} center classNames={{modal:'customModal'}}>
      <div className="team-main-content pt-40 pb-50">
        <div className="container">
        <div className="row">
            <div className="col-12">
              <div className="tp-section-wrapper text-center mb-30">
                {/* <span className="tp-section-subtitle mb-25">WHY US</span> */}
                <h2 className="tp-section-title" style={{textShadow: ".05em 0 0 rgba(179, 132, 201, .84)"}}>Software And Animation</h2>
              </div>
            </div>
          </div>
          <div className="team-page-content pr-100">
            <div className="row">
              <div className="col-12">
                <div className="team-page-text">
                  {/* <h3 className="team-title-text mb-25">About Hilextina</h3> */}
                  <div style={{backgroundColor:"#662F85"}}><h4 style={{color:"white"}}> <strong>SOFTWARE COURSES & ANIMATION COURSES</strong></h4></div>
                  
                  <p>Our training program prepares aspiring animators to become masters of animation
              techniques, opening doors to highly paid careers in the animation industry and enabling them
              to achieve great success.</p>
              <p><strong><span style={{color:"#662F85"}}>Sketching </span></strong>
              – Fundamentals of Drawing, Color Theory, Model-sheet</p>
              <p><strong><span style={{color:"#662F85"}}>Photoshop </span></strong>
              – Collage, Restoration, Retouching, Black and White to Color, Ma</p>
              <p><strong><span style={{color:"#662F85"}}>Autodesk Maya </span></strong>
              – Modeling, Texturing, Lighting, Rigging, Animation, Dynamics, Rendering</p>
              <p><strong><span style={{color:"#662F85"}}>Adobe Premiere </span></strong>
              – Video Editing</p>
              <p><strong><span style={{color:"#662F85"}}>Adobe After Effect </span></strong>
              – </p>
                </div>
                <div style={{backgroundColor:"#662F85"}}><h4 style={{color:"white"}}> <strong>Software Courses</strong></h4></div>
                    <p>Art of Drawing skills, Art & Graphics, Building
                        Construction, Art & Furniture Design, History,
                        Material, Art of Studio Design, Estimating &
                        Costing, Market survey, Work shops, Commercial
                        Site visits, Software, Special Highlights.</p >
                </div>
            </div>
          </div>
        </div>
      </div>
      </Modal>
      <div className="project-page-list pt-60 pb-50" id="course" >
        <div className="container">
        <div className="row">
            <div className="col-12">
              <div className="tp-section-wrapper text-center mb-20">
                <span className="tp-section-subtitle mb-5">Courses</span>
                <h2 className="tp-section-title" style={{textShadow: ".05em 0 0 rgba(179, 132, 201, .84)"}}>Explore Our Courses</h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center text-center">
            {portfolio_data.map((item,id) => (
            <div key={item.id} className="col-lg-6 col-md-6 col-12">
              <button onClick={()=>switchCase(item.id)}>
                {/* <HashLink to={item.link}> */}
                  <div className="pj-list-item mb-50">
                <div className="pj-list__img">
                  <img src={item.img} alt="" />
                </div>
                <span>
                  <a href="#">{item.tag }</a>
                </span>
                <h4 className="pj-list__title">
                  {item.title}
                </h4>
              </div>
              {/* </HashLink> */}
              </button>
            </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default PortfolioItems;
