import React from "react";
import FooterTwo from "../../common/Footer/FooterTwo";
import Menu from "../../common/Menu/Menu";
// import ContactWithEvent from "./ContactWithEvent";
import SingleEventBanner from "./SingleEventBanner";
import EventDetailsAbout from "./EventDetailsAbout";
// import EventDetailsHero from "./EventDetailsHero";

const EventDetails = () => {
  return (
    <>
      <Menu header_style_2={true} />
      <SingleEventBanner />
      {/* <EventDetailsHero /> */}
      <EventDetailsAbout />
      {/* <ContactWithEvent /> */}
      <FooterTwo />
    </>
  );
};

export default EventDetails;
