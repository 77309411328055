import React from "react";

const TeamDetailsAbout = () => {
  return (
    <>
      <div className="team-main-content pt-40 pb-130">
        <div className="container">
          <div className="team-page-content pr-40">
            <div className="row">
              <p className="mb-25">
                    One the top institutes for Interior Designing Courses in Pune, Core Institute is committed to provide
                    the best learning platform to get to it's students to their dream careers. With contemporary
                    knowledge taught by experts in the field, software trainings, live site projects, internships and
                    workshops, students become confident, industry-ready and highly employable.
                  </p>
              <div className="col-lg-6 col-sm-12">
                <div className="team-page-text">
                  {/* <h3 className="team-title-text mb-25">About Hilextina</h3> */}
                  

                  <div style={{backgroundColor:"#662F85"}}><h4 style={{color:"white"}}> <strong>Government Certified Diploma Course</strong></h4></div>
                  <strong><h4 style={{color:"#662F85"}}>ONE YEAR CERTIFICATE COURSE</h4></strong>
                    <p>Art of Drawing skills, Art & Graphics, Building
                        Construction, Art & Furniture Design, History,
                        Material, Art of Studio Design, Estimating &
                        Costing, Market survey, Work shops, Commercial
                        Site visits, Software, Special Highlights.</p>
                  <strong><h4 style={{color:"#662F85"}}>TWO YEARS ADVANCED DIPLOMA</h4></strong>
                  <p>Advanced Building Construction, Design Idea,
                      Material & Servicing, Life Space Planning, Model
                      Making, Art of Studio Design Estimating &
                      Costing, History, Market Survey, Workshops,
                      Commercial Site Visits, Software, Special
                      Highlights.</p>
                <strong><h4 style={{color:"#662F85"}}>Six Months Course</h4></strong>
                  <p>Basic Design, Interior Design (Residential),
                    Interior Construction, Services, Theory of
                    Materials, CAD, Graphic Design, Site Visit</p>
                </div>
                <div style={{backgroundColor:"#662F85"}}><h4 style={{color:"white"}}> <strong>Government Certified Degree Course</strong></h4></div>
                  <strong><h4 style={{color:"#662F85"}}>THREE YEAR DEGREE COURSE (B.Sc. ID)</h4></strong>
                    <p> All Diploma curriculum plus advanced Software
                        courses, Management Skills, Professional
                        Practices, Thesis Project, Training, Interior &
                        Exterior Treatments, Landscape Environmental
                        Control, Special Highlights, Industrial Interior
                        Designing and internship.
                    </p>
                </div>
                <div className="col-lg-6 col-sm-12">
                <div className="tp-ab-img text-md-end w-img">
                  <img src="./assets/img/courses/interior.png" alt="" />
                </div>
                </div>
            </div>
          </div>
          
        </div>
      </div>
    </>
  );
};

export default TeamDetailsAbout;
