import React, {useEffect} from "react";
import { useNavigate } from "react-router-dom";
// import { HashLink } from 'react-router-hash-link';
const Admission = () => {
    const navigate = useNavigate()
    useEffect(() => {
        navigate(`https://api.whatsapp.com/send?phone=+919921948837&text=I%20want%20to%20enquire%20about%20admission`)
        // setTimeout(() => {
        // }, 1000);
      });
  return (
    <>

    </>
  );
};

export default Admission;
