import React from "react";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContactInfo = () => {
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required')
    .min(3, 'Name must be at least 3 characters')
    .max(50, 'Name must not exceed 50 characters'),
    mobile: Yup.string()
      .required('mobile is required')
      .matches(phoneRegExp, 'Mobile number is not valid')
      .min(10, "Mobile number is not valid")
      .max(10, "Mobile number is not valid"),
    email: Yup.string()
      .required('Email is required')
      .email('Email is invalid'),
    city: Yup.string().required('City is required')
    .min(3, 'City must be at least 3 characters')
    .max(50, 'City must not exceed 50 characters'),
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  });
  const onSubmit = data => {
    // console.log(JSON.stringify(data, null, 2));
    // console.log(data)
    axios.post("https://coreinstitute.in/api/enquiry/add",
      data
    )
    .then(response => {
      // console.log(response);
      reset();
      toast.success(response.data.data.description, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    })
    .catch(error => {
      if (error.response) {
        //response status is an error code
        console.log(error.response.status);
        toast.error('🦄 Wow so easy!', {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
      }
      else if (error.request) {
        //response not received though the request was sent
        console.log(error.request);
        toast.error('🦄 Wow so easy!', {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
      }
      else {
        //an error occurred when setting up the request
        console.log(error.message);
        toast.error(error.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
      }
    });
  };
  return (
    <>
      <div className="contact-from-section pt-60" id="contact">
        <div className="container">
        <div className="row">
            <div className="col-12">
              <div className="tp-section-wrapper text-center mb-70">
                <span className="tp-section-subtitle mb-25" >Contact</span>
                <h2 className="tp-section-title" style={{textShadow: ".05em 0 0 rgba(179, 132, 201, .84)"}}>Reach Us Through</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div className="ct-info__box text-center pt-50 pb-10 mb-30" style={{boxShadow: "#662F85 0px 15px 10px -10px"}}>
                <span className="mb-25 d-inline-block">
                  <svg
                    width="42"
                    height="50"
                    viewBox="0 0 42 62"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.002 2.06609C31.4232 2.06609 39.9024 10.4099 39.9024 20.6667C39.9024 23.9286 39.0239 27.1421 37.3616 29.9679L21 57.8659L4.63647 29.966C2.97608 27.144 2.09764 23.9286 2.09764 20.6667C2.09764 10.4099 10.5768 2.06609 21 2.06609H21.002ZM21.002 27.898C25.0554 27.898 28.3506 24.6535 28.3506 20.6667C28.3506 16.6799 25.0535 13.4354 21.002 13.4354C16.9485 13.4354 13.6514 16.6799 13.6514 20.6667C13.6514 24.6535 16.9485 27.898 21.002 27.898ZM21.002 0C9.40293 0 0 9.25474 0 20.6667C0 24.4364 1.03011 27.958 2.82048 31.001C9.92003 43.1068 13.9005 49.8941 21 62L39.1795 31.001C40.9699 27.958 42 24.4364 42 20.6667C42 9.25474 32.5971 0 21 0H21.002ZM21.002 25.8319C18.1027 25.8319 15.751 23.5196 15.751 20.6667C15.751 17.8156 18.1027 15.5015 21.002 15.5015C23.9012 15.5015 26.251 17.8156 26.251 20.6667C26.251 23.5196 23.9012 25.8319 21.002 25.8319Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
                <h4 className="ct-info__box-title">Address</h4>
                <p className="p-0 text-center">
                1st Floor, Sahil Arcade B Wing, next to Hotel Panchali,
Jangali Maharaj Rd, Shivajinagar, Pune, Maharashtra 411005 
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="ct-info__box text-center pt-50 pb-50 mb-30" style={{boxShadow: "#662F85 0px 15px 10px -10px"}}>
                <span className="mb-25 d-inline-block">
                  <svg
                    width="35"
                    height="58"
                    viewBox="0 0 35 58"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M32.0377 0H2.96231C1.3273 0 0 1.30348 0 2.90917V55.0908C0 56.6985 1.3273 58 2.96231 58H32.0377C33.6727 58 35 56.6985 35 55.0908V2.90917C35 1.30348 33.6727 0 32.0377 0ZM32.8131 55.0908C32.8131 55.5099 32.4664 55.8504 32.0397 55.8504H2.96436C2.53766 55.8504 2.19096 55.5099 2.19096 55.0908V45.1183H32.8152V55.0908H32.8131ZM32.8131 42.9686H2.18891V8.63483H32.8131V42.9686ZM32.8131 6.4872H2.18891V2.90917C2.18891 2.49012 2.53561 2.14763 2.96231 2.14763H32.0377C32.4644 2.14763 32.8111 2.49012 32.8111 2.90917V6.4872H32.8131Z"
                      fill="black"
                    />
                    <path d="M14 49H21V51H14V49Z" fill="currentColor" />
                  </svg>
                </span>
                <h3 className="ct-info__box-title">Phone Number</h3>
                <p className="p-0 text-center">
                  <a href="tel:+919921948837">+91 992 194 8837</a> <br />
                  {/* <a href="tel:+1255-568-6523">+1255 - 568 - 6523</a> */}
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="ct-info__box text-center pt-50 pb-50 mb-30" style={{boxShadow: "#662F85 0px 15px 10px -10px"}}>
                <span className="mb-25 d-inline-block">
                  <svg
                    width="49"
                    height="56"
                    viewBox="0 0 49 56"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M48.818 23.0144L27.5018 1.26962C26.7004 0.451961 25.6342 0 24.5 0C23.3658 0 22.2996 0.450085 21.4982 1.26962L0 23.1982V56H49V23.2019L48.818 23.0162V23.0144ZM3.13971 22.8231L22.8842 2.68176C23.7482 1.80034 25.2537 1.80034 26.1176 2.68176L46.7904 23.7739L32.7022 38.1466L27.5018 32.8412C26.7004 32.0236 25.6342 31.5716 24.5 31.5716C23.3658 31.5716 22.2996 32.0236 21.4982 32.8412L16.2206 38.2235L2.13235 23.8508L3.13971 22.8231ZM14.8346 39.6356L14.6893 39.7857L14.6875 39.7838L1.9614 52.7669V26.5025L14.8364 39.6356H14.8346ZM3.5239 53.999L4.57721 52.9244H4.58088L22.8842 34.2534C23.7482 33.372 25.2537 33.372 26.1176 34.2534L45.4761 53.999H3.5239ZM47.0404 27.1927V52.7688L34.0882 39.5606L47.0404 26.3469V27.1927Z"
                      fill="black"
                    />
                  </svg>
                </span>
                <h3 className="ct-info__box-title">Mail Adress</h3>
                <p className="p-0 text-center">
                  <a href="info@coreinstitute.in">info@coreinstitute.in</a>{" "}
                  {/* <br /> */}
                  {/* <a href="mailto:info@yourdomain.com">info@yourdomain.com</a> */}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-6">
              <div className="tp-ct-form white-bg pl-30 pr-30 pt-80 pb-130">
              <form onSubmit={handleSubmit(onSubmit)} className="mb-1">
                      <div className="form-group mb-1" >
                        <label className="text-muted font-size-15 mb-1">Name<span style={{color:"red"}}>*</span></label><br/>
                        <input
                          name="name"
                          type="text"
                          {...register('name')}
                          className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                        />
                        <div className="invalid-feedback">{errors.name?.message}</div>
                      </div>
                      <br/>
                      <div className="form-group mb-1">
                        <label>Email <span style={{color:"red"}}>*</span></label><br/>
                        <input
                          name="email"
                          type="text"
                          {...register('email')}
                          className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                        />
                        <div className="invalid-feedback">{errors.email?.message}</div>
                      </div><br/>
                      <div className="form-group mb-1">
                        <label>Mobile Number <span style={{color:"red"}}>*</span></label><br/>
                        <input
                          name="mobile"
                          type="text"
                          {...register('mobile')}
                          className={`form-control ${errors.mobile ? 'is-invalid' : ''}`}
                        />
                        <div className="invalid-feedback">{errors.mobile?.message}</div>
                      </div>
                      <div className="form-group mb-1 ">
                        <label>City <span style={{color:"red"}}>*</span></label><br/>
                        <input
                          name="city"
                          type="text"
                          {...register('city')}
                          className={`form-control ${errors.city ? 'is-invalid' : ''}`}
                        />
                        <div className="invalid-feedback">{errors.city?.message}</div>
                      </div>
                      <div className="form-group mb-1">
                        <label>Course Type</label><br/>
                        {/* <input
                          name="courseType"
                          type="text"
                          {...register('courseType')}
                          className={`form-control ${errors.courseType ? 'is-invalid' : ''}`}
                        /> */}
                        <select class="form-control" {...register("courseType")}>
                          <option value="Event Management">Event Management</option>
                          <option value="Fashion Designing">Fashion Designing</option>
                          <option value="Interior Design">Interior Design</option>
                          <option value="Software Course">Software Course</option>
                        </select>
                        {/* <div className="invalid-feedback">{errors.courseType?.message}</div> */}
                      </div>
                      <div className="d-flex justify-content-center mt-10">
                        <button type="submit" 
                        className="btn btn-md formbutton"
                        style={{backgroundColor:'#662F85', color:"white"}}>
                          Submit
                        </button>
                      </div>
                    </form>
                <p className="ajax-response mt-20 text-center"></p>
              </div>
            </div>
            <div className="col-lg-6">
            <div className="tp-ct-map">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3783.071770130355!2d73.8502914!3d18.5256586!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c07c5a1657e3%3A0xf7c7b0c2ca2543ff!2sSahil%20Arcade%2C%20Jangali%20Maharaj%20Rd%2C%20Shivajinagar%2C%20Pune%2C%20Maharashtra%20411005!5e0!3m2!1sen!2sin!4v1685272576627!5m2!1sen!2sin" style={{"width":"600", "height":"400", "style":"border:0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
            </div>
          </div>
        </div>
        
      </div>
    </>
  );
};

export default ContactInfo;
