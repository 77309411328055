import React from "react";
// import ModalVideo from "react-modal-video";

const VideoPopup = ({
  isVideoOpen,
  setIsVideoOpen,
  videoId = "EW4ZYb3mCZk",
}) => {
  return (<></>
    // <ModalVideo
    //   channel="youtube"
    //   autoplay
    //   isOpen={isVideoOpen}
    //   videoId={videoId}
    //   onClose={() => setIsVideoOpen(false)}
    // />
  );
};

export default VideoPopup;
