import React from 'react';
import { Route, Routes } from "react-router-dom";
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// internal
import BackToTop from '../components/BackToTop';
import AnimateMouse from '../components/common/AnimateMouse';
import ContextProvider from '../components/context/ContextProvider';
import Home from '../components/Home/Home';
import HomeThree from '../components/HomeThree/HomeThree';
import HomeTwo from '../components/HomeTwo/HomeTwo';
import About from '../components/Pages/About/About';
import Blog from '../components/Pages/Blog/Blog';
import BlogDetails from '../components/Pages/BlogDetails/BlogDetails';
import ContactUs from '../components/Pages/ContactUs/ContactUs';
import FAQ from '../components/Pages/FAQ/FAQ';
import Portfolio from '../components/Pages/Portfolio/Portfolio';
import PortfolioDetails from '../components/Pages/PortfolioDetails/PortfolioDetails';
import Price from '../components/Pages/Price/Price';
import Service from '../components/Pages/Service/Service';
import ServiceDetails from '../components/Pages/ServiceDetails/ServiceDetails';
import TeamDetails from '../components/Pages/TeamDetails/TeamDetails';
import TeamPage from '../components/Pages/TeamPage/TeamPage';
import EventDetails from '../components/HomeTwo/Event/EventDetails';
import FashionDetails from '../components/HomeTwo/Fashion/FashionDetails';
import InteriorDetails from '../components/HomeTwo/Interior/InteriorDetails';
import SoftwareDetails from '../components/HomeTwo/Software/SoftwareDetails';
import Enquiry from '../components/HomeTwo/EnquiryList/EnquiryMain'; 
import ScrollToTop from './Wrapper';
import Admission from '../components/HomeTwo/Admission';

const AppNavigation = () => {
  const [open, setOpen] = React.useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const [firstRender, setFirstRender] = React.useState(false);
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required')
    .min(3, 'Name must be at least 3 characters')
    .max(50, 'Name must not exceed 50 characters'),
    mobile: Yup.string()
      .required('mobile is required')
      .matches(phoneRegExp, 'Mobile number is not valid')
      .min(10, "Mobile number is not valid")
      .max(10, "Mobile number is not valid"),
    email: Yup.string()
      .required('Email is required')
      .email('Email is invalid'),
    city: Yup.string().required('City is required')
    .min(3, 'City must be at least 3 characters')
    .max(50, 'City must not exceed 50 characters'),
  });
  const {
    register,
    handleSubmit,
    // reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  });
  const onSubmit = data => {
    // console.log(JSON.stringify(data, null, 2));
    // console.log(data)
    axios.post("https://coreinstitute.in/api/enquiry/add",
      data
    )
    .then(response => {
      // console.log(response);
      onCloseModal();
      toast.success(response.data.data.description, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    })
    .catch(error => {
      if (error.response) {
        //response status is an error code
        console.log(error.response.status);
        toast.error('🦄 Wow so easy!', {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
      }
      else if (error.request) {
        //response not received though the request was sent
        console.log(error.request);
        toast.error('🦄 Wow so easy!', {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
      }
      else {
        //an error occurred when setting up the request
        console.log(error.message);
        toast.error(error.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
      }
    });
  };
  // let isOpened = false;
  const RegisterModal=
        <Modal size="xl"  open={open} onClose={onCloseModal} center classNames={{modal:'customModal'}}>
        <div className="team-main-content pt-10 pb-10">
            <div className="container">
            <div className="row">
                <div className="col-12">
                <div className="tp-section-wrapper text-center mb-10">
                    {/* <span className="tp-section-subtitle mb-25">WHY US</span> */}
                    <strong className="wrapper">
                      <h3 className="formheading">ENQUIRY FORM</h3>
                    </strong>
                    {/* <h2 className="tp-section-title" style={{textShadow: ".05em 0 0 rgba(179, 132, 201, .84)"}}>Enquiry Form</h2> */}
                </div>
                </div>
            </div>
            <div className="">
                <div className="row">
                    <div className="col-12">
                    <form onSubmit={handleSubmit(onSubmit)} className="mb-1">
                      <div className="form-group mb-1" >
                        <label className="text-muted font-size-15 mb-1">Name<span style={{color:"red"}}>*</span></label>
                        <input
                          name="name"
                          type="text"
                          {...register('name')}
                          className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                        />
                        <div className="invalid-feedback">{errors.name?.message}</div>
                      </div>
                      <div className="form-group mb-1">
                        <label>Email <span style={{color:"red"}}>*</span></label>
                        <input
                          name="email"
                          type="text"
                          {...register('email')}
                          className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                        />
                        <div className="invalid-feedback">{errors.email?.message}</div>
                      </div>
                      <div className="form-group mb-1">
                        <label>Mobile Number <span style={{color:"red"}}>*</span></label>
                        <input
                          name="mobile"
                          type="text"
                          {...register('mobile')}
                          className={`form-control ${errors.mobile ? 'is-invalid' : ''}`}
                        />
                        <div className="invalid-feedback">{errors.mobile?.message}</div>
                      </div>
                      <div className='row'>
                      <div className="form-group mb-1 col-md-6">
                        <label>City <span style={{color:"red"}}>*</span></label>
                        <input
                          name="city"
                          type="text"
                          {...register('city')}
                          className={`form-control ${errors.city ? 'is-invalid' : ''}`}
                        />
                        <div className="invalid-feedback">{errors.city?.message}</div>
                      </div>
                      <div className="form-group mb-1 col-md-6">
                        <label>Course Type</label>
                        {/* <input
                          name="courseType"
                          type="text"
                          {...register('courseType')}
                          className={`form-control ${errors.courseType ? 'is-invalid' : ''}`}
                        /> */}
                        <select class="form-control" {...register("courseType")}>
                          <option value="Event Management">Event Management</option>
                          <option value="Fashion Designing">Fashion Designing</option>
                          <option value="Interior Design">Interior Design</option>
                          <option value="Software Course">Software Course</option>
                        </select>
                        {/* <div className="invalid-feedback">{errors.courseType?.message}</div> */}
                      </div>
                      </div>
                      <div className="d-flex justify-content-center mt-10">
                        <button type="submit" 
                        className="btn btn-md formbutton"
                        style={{backgroundColor:'#662F85', color:"white"}}>
                          Submit
                        </button>
                      </div>
                    </form>
                    </div>
                </div>
            </div>
            </div>
        </div>
        </Modal>;
        React.useEffect(() => {
           const handleScroll=() => {
            if ( window.scrollY > window.innerHeight / 3) {
              setOpen(true);
              setFirstRender(true);
            }
          }
          if (!firstRender) {
            window.addEventListener("scroll", handleScroll);
          }
          return ()=>window.removeEventListener("scroll", handleScroll)
        }, [firstRender])
  
  
  
  return (
    <ContextProvider>
      <AnimateMouse/>
      {RegisterModal}
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        />
      <ScrollToTop></ScrollToTop>
      <Routes>
        <Route path="/" element={<HomeTwo />} />
        <Route path="/event" element={<EventDetails />} />
        <Route path="/fashion" element={<FashionDetails />} />
        <Route path="/interior" element={<InteriorDetails />} />
        <Route path="/software" element={<SoftwareDetails />} />
        {/* <Route path="/admission" element={<Admission />} /> */}
        <Route path="/admission" Component={() => { window.location = 'https://api.whatsapp.com/send?phone=+919921948837&text=I%20want%20to%20enquire%20about%20admission'; return null;} } />
        <Route path="/home-2" element={<HomeTwo />} />
        <Route path="/home-3" element={<HomeThree />} />
        <Route path="/about" element={<About />} />
        <Route path="/team" element={<TeamPage/>} />
        <Route path="/team-details" element={<TeamDetails />} />
        <Route path="/service" element={<Service />} />
        <Route path="/service-details" element={<ServiceDetails />} />
        <Route path="/price" element={<Price/>} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/portfolio-details" element={<PortfolioDetails />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog-details" element={<BlogDetails />} />
        <Route exact path="/enquiry" element={<Enquiry />} />
      </Routes>
      <BackToTop/>
    </ContextProvider>
  );
};

export default AppNavigation;