import React, {useEffect, useState} from "react";
import axios from 'axios';
import DataTable from 'react-data-table-component';

const ContactInfo = () => {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [totalRows, setTotalRows] = useState(0);
	const [perPage, setPerPage] = useState(10);
  const columns = [
    {
        name: 'Name',
        selector: row => row.name,
    },
    {
        name: 'Email',
        selector: row => row.email,
    },
    {
      name: 'Mobile',
      selector: row => row.mobile,
  },
  {
      name: 'City',
      selector: row => row.city,
  },
  {
    name: 'Course Type',
    selector: row => row.courseType,
},
];

	const fetchUsers = async page => {
		setLoading(true);

		const response = await axios.get(`https://coreinstitute.in/api/enquiry/list?page_no=${page}&limit=${perPage}`);
    console.log(response.data.data.items[0].users);
		setData(response.data.data.items[0].users);
		setTotalRows(response.data.data.items[0].numberOfPages);
		setLoading(false);
	};

	const handlePageChange = page => {
		fetchUsers(page);
	};

	const handlePerRowsChange = async (newPerPage, page) => {
		setLoading(true);

		const response = await axios.get(`https://coreinstitute.in/api/enquiry/list?page_no=${page}&limit=${perPage}`);

		setData(response.data.data.items[0].users);
		setPerPage(newPerPage);
		setLoading(false);
	};

	useEffect(() => {
		fetchUsers(1); // fetch page 1 of users
		
	}, []);

	return (
		<DataTable
			title="Enquiry List "
			columns={columns}
			data={data}
			progressPending={loading}
			pagination
			paginationServer
			paginationTotalRows={totalRows}
			onChangeRowsPerPage={handlePerRowsChange}
			onChangePage={handlePageChange}
      responsive={true}
      highlightOnHover
      paginationRowsPerPageOptions={[10, 15, 20]}
      // paginationComponentOptions={paginationComponentOptions}
		/>
	);
}

export default ContactInfo;
