import React from "react";
import FooterTwo from "../../common/Footer/FooterTwo";
import Menu from "../../common/Menu/Menu";
// import ContactWithInterior from "./ContactWithInterior";
import SingleInteriorBanner from "./SingleInteriorBanner";
import InteriorDetailsAbout from "./InteriorDetailsAbout";
// import InteriorDetailsHero from "./InteriorDetailsHero";

const InteriorDetails = () => {
  return (
    <>
      <Menu header_style_2={true} />
      <SingleInteriorBanner />
      {/* <InteriorDetailsHero /> */}
      <InteriorDetailsAbout />
      {/* <ContactWithInterior /> */}
      <FooterTwo />
    </>
  );
};

export default InteriorDetails;
