const menu_data = [
  {
    id: 1,
    // hasDropdown: true,
    title: 'Home',
    link: '/#home',
    // submenus: [
    //   { title: 'Home Style 1', link: '/' },
    //   { title: 'Home Style 2', link: '/home-2' },
    //   { title: 'Home Style 3', link: '/home-3' },
    // ]
  },
  {
    id: 2,
    title: 'Why Us',
    link: '/#about',
  },
  {
    id: 3,
    // hasDropdown: true,
    title: 'Admission',
    link: '/#whocan',
    // submenus: [
    //   { title: 'Team', link: '/team' },
    //   { title: 'Team Details', link: '/team-details' },
    //   { title: 'Service', link: '/service' },
    //   { title: 'Service Details', link: '/service-details' },
    //   { title: 'Price', link: '/price' },
    //   { title: 'Contact', link: '/contact' },
    //   { title: 'Faq', link: '/faq' },
    // ]
  },
  {
    id: 4,
    // hasDropdown: true,
    title: 'Courses',
    link: '/#course',
    // submenus: [
    //   { title: 'Portfolio', link: '/portfolio' },
    //   { title: 'Portfolio Details', link: '/portfolio-details' },
    // ]
  },
  {
    id: 5,
    // hasDropdown: true,
    title: 'LIFE@CORE',
    link: '/#testimonial',
    // submenus: [
    //   { title: 'Blog', link: '/blog' },
    //   { title: 'Blog Details', link: '/blog-details' },
    // ]
  },
  {
    id: 6,
    title: 'Contact',
    link: '/#contact',
  },
]

export default menu_data;
