import React, { useRef, useState, useEffect } from "react";
import Slider from "react-slick";
import slider from "../../data/SliderHomePageTwo.json";
import { Container, Row, Col, Form, FormGroup, Label, Input } from "reactstrap";

const settings = {
  autoplay: true,
  autoplaySpeed: 4000,
  dots: false,
  fade: true,
  arrows: false,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        arrows: false,
      },
    },
  ],
};

const SliderHomeThree = () => {
  const sliderRef = useRef(null);
  const [mobile, setMobile] = useState(window.innerWidth <= 992);
const handleWindowSizeChange = () => {
  setMobile(window.innerWidth <= 992);
}

useEffect(() => {
  window.addEventListener('resize', handleWindowSizeChange);
  return () => {
    window.removeEventListener('resize', handleWindowSizeChange);
  }
}, []);

  return (
    <>
      {/* <!-- slider-3 --> */}
      {!mobile &&<div className="slider-home-three" id="home">
        <div className="tp-slider-area-3 p-relative">
          <Slider ref={sliderRef} {...settings} className="tp-silder-acive-3">
            {slider?.slider2.map((item) => (
              <div key={item.id}>
                <div
                  className="tp-slider-item-3 tp-slider-height-3 silder-overlay"
                  style={{ backgroundImage: `url(${item.img})` }}
                >
            {/* <Container >
            <Row className="align-items-center ml-190 pt-35" >
              <Col xl={7} lg={7} className="mx-2 "  >
                <div className="mx-auto rounded bg-white mt-5 mt-lg-0 p-4" style={{boxShadow: "0 4px 8px 0 rgb(102, 47, 133)",
    borderRadius: "15px",}}>
                  <div className="mb-4"></div>
                  <Form className="registration-form" >
                    <strong className="wrapper">
                      <h3 className="formheading">ADMISSION FORM</h3>
                    </strong>
                    <FormGroup className="mb-4">
                      <Label
                        htmlFor="exampleFormControlInput1"
                        className="text-muted font-size-15 mb-2"
                      >
                        Your Name <span style={{color:"red"}}>*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder=""
                      />
                    </FormGroup>
                    <FormGroup className="mb-4">
                      <Label
                        htmlFor="exampleFormControlInput1"
                        className="text-muted font-size-15 mb-2"
                      >
                        Your email <span style={{color:"red"}}>*</span>
                      </Label>
                      <Input
                        type="email"
                        className="form-control"
                        id="exampleFormControlInput2"
                        placeholder=""
                      />
                    </FormGroup>
                    <FormGroup className="mb-4">
                      <Label
                        htmlFor="exampleFormControlInput1"
                        className="text-muted font-size-15 mb-2"
                      >
                        Mobile <span style={{color:"red"}}>*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="exampleFormControlInput3"
                        placeholder=""
                      />
                    </FormGroup>
                    <div className="d-grid">
                      <button
                        type="submit"
                        className="btn btn-sm text-uppercase formbutton"
                        style={{backgroundColor:'#662F85', color:"white"}}
                        
                      >
                        Get Started
                      </button>
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container> */}
          </div>
              </div>
            ))}
          </Slider>
          <div className="slider-arrow-2 d-flex justify-content-between align-items-center">
            <button
              onClick={() => sliderRef.current?.slickPrev()}
              type="button"
              className="slick-prev slick-arrow"
            >
              <span>
                <i className="fal fa-angle-left"></i>
              </span>
            </button>
            <button
              onClick={() => sliderRef.current?.slickNext()}
              type="button"
              className="slick-next slick-arrow"
            >
              <span>
                <i className="fal fa-angle-right"></i>
              </span>
            </button>
          </div>
        </div>
      </div>}
      {/* for mobile version */}
      {mobile &&<div className="slider-home-three" id="home">
        <div className="tp-slider-area-3 p-relative">
          <Slider ref={sliderRef} {...settings} className="tp-silder-acive-3">
            {slider?.slider2.map((item) => (
              <div key={item.id}>
                <div
                  className="tp-slider-item-3 tp-slider-height-3 silder-overlay"
                  style={{ backgroundImage: `url(${item.img})` }}
                >
          </div>
              </div>
            ))}
          </Slider>
          <div className="slider-arrow-2 d-flex justify-content-between align-items-center">
            <button
              onClick={() => sliderRef.current?.slickPrev()}
              type="button"
              className="slick-prev slick-arrow"
            >
              <span>
                <i className="fal fa-angle-left"></i>
              </span>
            </button>
            <button
              onClick={() => sliderRef.current?.slickNext()}
              type="button"
              className="slick-next slick-arrow"
            >
              <span>
                <i className="fal fa-angle-right"></i>
              </span>
            </button>
          </div>
          {/* <Container >
            <Row className="align-items-center pt-35">
              <Col xl={5} lg={6} className="ms-lg-auto" >
                <div className="mx-auto rounded bg-white mt-5 mt-lg-0 p-4" style={{boxShadow: "0 4px 8px 0 rgb(102, 47, 133)",
    borderRadius: "15px",}}>
                  <div className="mb-4"></div>
                  <Form className="registration-form" >
                    <strong className="wrapper" style={{textAlign:"center !important"}}><h3 className="formheading">ADMISSION FORM</h3></strong>
                    <FormGroup className="mb-4">
                      <Label
                        htmlFor="exampleFormControlInput1"
                        className="text-muted font-size-15 mb-2"
                      >
                        Your Name <span style={{color:"red"}}>*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder=""
                      />
                    </FormGroup>
                    <FormGroup className="mb-4">
                      <Label
                        htmlFor="exampleFormControlInput1"
                        className="text-muted font-size-15 mb-2"
                      >
                        Your email*
                      </Label>
                      <Input
                        type="email"
                        className="form-control"
                        id="exampleFormControlInput2"
                        placeholder=""
                      />
                    </FormGroup>
                    <FormGroup className="mb-4">
                      <Label
                        htmlFor="exampleFormControlInput1"
                        className="text-muted font-size-15 mb-2"
                      >
                        Mobile*
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="exampleFormControlInput3"
                        placeholder=""
                      />
                    </FormGroup>
                    <div className="d-grid">
                      <button
                        type="submit"
                        className="btn btn-sm text-uppercase formbutton"
                        style={{backgroundColor:'#662F85', color:"white"}}
                        
                      >
                        Get Started
                      </button>
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container> */}
        </div>
      </div>
      }
      {/* <!-- slider -3 --> */}
    </>
  );
};

export default SliderHomeThree;
