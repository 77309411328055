import React from "react";
import FooterTwo from "../../common/Footer/FooterTwo";
import Menu from "../../common/Menu/Menu";
// import ContactWithSoftware from "./ContactWithSoftware";
import SingleSoftwareBanner from "./SingleSoftwareBanner";
import SoftwareDetailsAbout from "./SoftwareDetailsAbout";
// import SoftwareDetailsHero from "./SoftwareDetailsHero";

const SoftwareDetails = () => {
  return (
    <>
      <Menu header_style_2={true} />
      <SingleSoftwareBanner />
      {/* <SoftwareDetailsHero /> */}
      <SoftwareDetailsAbout />
      {/* <ContactWithSoftware /> */}
      <FooterTwo />
    </>
  );
};

export default SoftwareDetails;
