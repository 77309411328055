import React from "react";

const TeamDetailsAbout = () => {
  return (
    <>
      <div className="team-main-content pt-40 pb-130">
        <div className="container">
          <div className="team-page-content pr-100">
            <div className="row">
              <div className="col-12">
                <div className="team-page-text">
                  {/* <h3 className="team-title-text mb-25">About Hilextina</h3> */}
                  <div style={{backgroundColor:"#662F85"}}><h4 style={{color:"white"}}> <strong>SOFTWARE COURSES & ANIMATION COURSES</strong></h4></div>
                  
                  <p>Our training program prepares aspiring animators to become masters of animation
              techniques, opening doors to highly paid careers in the animation industry and enabling them
              to achieve great success.</p>
              <p><strong><span style={{color:"#662F85"}}>Sketching </span></strong>
              – Fundamentals of Drawing, Color Theory, Model-sheet</p>
              <p><strong><span style={{color:"#662F85"}}>Photoshop </span></strong>
              – Collage, Restoration, Retouching, Black and White to Color, Ma</p>
              <p><strong><span style={{color:"#662F85"}}>Autodesk Maya </span></strong>
              – Modeling, Texturing, Lighting, Rigging, Animation, Dynamics, Rendering</p>
              <p><strong><span style={{color:"#662F85"}}>Adobe Premiere </span></strong>
              – Video Editing</p>
              <p><strong><span style={{color:"#662F85"}}>Adobe After Effect </span></strong>
              – </p>
                </div>
                <div style={{backgroundColor:"#662F85"}}><h4 style={{color:"white"}}> <strong>Software Courses</strong></h4></div>
                    <p>Art of Drawing skills, Art & Graphics, Building
                        Construction, Art & Furniture Design, History,
                        Material, Art of Studio Design, Estimating &
                        Costing, Market survey, Work shops, Commercial
                        Site visits, Software, Special Highlights.</p >
                </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamDetailsAbout;
