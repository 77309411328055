import React from "react";

const TeamDetailsAbout = () => {
  return (
    <>
      <div className="team-main-content pt-40 pb-130">
        <div className="container">
          <div className="team-page-content pr-40">
          <p className="mb-25">
                    Fuel your passion for fashion by enrolling in fashion designing (FD) courses at Core Institute: your
                    gateway to the fashion world. Core Institute specializes in creating promising careers in the fashion
                    industry, drawing on it's years of experience and hand-on learning approach. This course equips
                    Students with the skills necessary to identify top-notch master tailors, pattern makers, and simple makers.
                    FD course will cover eveything from sourcing fabrics and suppliers to establishing your very own boutique.
                    Embark on a journey in the fashion industry with Core Institute's Fashion designing course.
                  </p>
            <div className="row">
              <div className="col-lg-6 col-sm-12">
                <div className="team-page-text">
                  {/* <h3 className="team-title-text mb-25">About Hilextina</h3> */}
                  
                  <div style={{backgroundColor:"#662F85"}}><h4 style={{color:"white"}}> <strong>THREE YEAR PROF, ADV, DIPLOMA</strong></h4></div>
                  {/* <strong><h4 style={{color:"#662F85"}}>ONE YEAR CERTIFICATE COURSE</h4></strong> */}
                    <p>Advanced pattern making, grading, advanced garment construction, advance fashion illustration,
                       portfolio, advance embriodery, accessory design, photography workshop, fabric formation and
                       processing, research craft documentation make up, styling, portfolio making, Guest Faculty
                       Lectures, Fashon CAD, Designer Studio Visit, Professional Fashion Management and Advance Fashion Styling.
                    </p>
                </div>
                <div style={{backgroundColor:"#662F85"}}><h4 style={{color:"white"}}> <strong>ONE YEAR DIPLOMA COURSE</strong></h4></div>
                    <p>Basic Design & Design idea illustration, emriodery, pattern making, garment constructions,
                       textiles, fashion CAD, Range Development, Workshops, Industrial Visit, Portfolio,
                       Fashion Marketing Management and Fashion Styling.</p>
                       <div style={{backgroundColor:"#662F85"}}><h4 style={{color:"white"}}> <strong>TWO YEAR ADVANCED DIPLOMA COURSE</strong></h4></div>
                    <p>Design idea & illustration, Embriodery, Pattern making, Garment constructions, textiles, history of fashion, fashion CAD,
                       Range development, workshops, Guest Faculty Lectures, Industrial + Design Studio Visits, Designer PPT, Fashion Merchandising,
                       portfolio, advance fashion marketing management, advance styling and fashion communication.
                    </p>
                </div>
                <div className="col-lg-6 col-sm-12">
                <div className="tp-ab-img text-md-end w-img">
                  <img src="./assets/img/courses/fashion.png" alt="" />
                </div>
                </div>
                </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamDetailsAbout;
