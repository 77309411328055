import React from "react";

const TeamDetailsAbout = () => {
  return (
    <>
      <div className="team-main-content pt-40 pb-130">
        <div className="container">
          <div className="team-page-content pr-40">
          <p className="mb-25">
                  Turn your event management passion into a high-paying career with practical trainings and
                  Workshop oriented learning at Core Institute. Learn the technicalities to plan, promote, and
                  execute events like weddings, Music concerts and more. Enhance your creativity, networking,
                  people management, and communication skills to become a successful event manager. Our
                  curriculum covers everything from visualizing events to adhering to budgets and legalities.
                  Join now and become the driving force behind successful events!
                  </p>
            <div className="row">
              <div className="col-lg-6 col-sm-12">
                <div className="team-page-text">
                  {/* <h3 className="team-title-text mb-25">About Hilextina</h3> */}
                  
                  <div style={{backgroundColor:"#662F85"}}><h4 style={{color:"white"}}> <strong>ONE YEAR ADVANCE CERTIFICATION</strong></h4></div>
                        <div className="row">
                        <div className="col-lg-6" >
                          <ul style={{listStyleType:'disc'}}>
                            <li>✔ Communication & Presentation Skills</li>
                            <li>✔ Event Concept & Creative Collaterals</li>
                            <li>✔ Set Design & Venue Costrctions</li>
                            <li>✔ Event Operations & Productions</li>
                            <li>✔ Sponsorship</li>
                            <li>✔ Celebrity Artist Management</li>
                            <li>✔ Corporate Event</li>
                          </ul>
                        </div>
                        <div className="col-lg-6">
                          <ul>
                            <li>✔ Wedding Event</li>
                            <li>✔ Personal Celebration</li>
                            <li>✔ Birthday Party, Anniversaries, Name Ceremony</li>
                            <li>✔ Exhibition & Trade Fairs</li>
                            <li>✔ Music Events & Concerts</li>
                            <li>✔ Political Event Management</li>
                            <li>✔ Anchoring</li>
                          </ul>
                        </div>
                        </div> 
                </div>
                <div style={{backgroundColor:"#662F85"}}><h4 style={{color:"white"}}> <strong>SIX MONTH CERTIFICATION</strong></h4></div>
                <div className="row">
                         <ul>
                            <li>✔ Communication & Presentation Skills</li>
                            <li>✔ Event Concept & Creative Collaterals</li>
                            <li>✔ Set Design & Venue Construction</li>
                            <li>✔ Weddings Events</li>
                            <li>✔ Personal Celebration</li>
                          </ul>
                        </div> 
                </div>
                <div className="col-lg-6 col-sm-12">
                <div className="tp-ab-img text-md-end w-img">
                  <img src="./assets/img/courses/event.png" alt="" />
                </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamDetailsAbout;
