import React from "react";
import ScrollSpy from "react-ui-scrollspy";
import Funfact from "./Funfact";
import FooterTwo from "../common/Footer/FooterTwo";
// import FullScreenBtn from "../common/FullScreenBtn";
import Menu from "../common/Menu/Menu";
import Testimonial from "./Testimonial";
// import BlogHomeTwo from "./BlogHomeTwo";
// import GalleryHomeTwo from "./GalleryHomeTwo";
// import Services from "./Services";
// import SliderHomeTwo from "./SliderHomeTwo";
import SliderHomeThree from "./SliderHomeForm";
// import TopCategoryHomePageTwo from "./TopCategoryHomePageTwo";
// import TopCtaAreaTwo from "./TopCtaAreaTwo";
import AboutCompany from "./AboutCompany";
import Courses from "./Courses";
import WhoCan from "./WhoCan";
import Contact from "./Contact";
import PortfolioThree from "./PortfolioThree";


const HomeTwo = () => {
  
  return (
    <>
      <Menu header_style_2={true} />
      <ScrollSpy scrollThrottle={50} useBoxMethod={true} offsetBottom={2}>
      {/* <TopCtaAreaTwo /> */}
      {/* <SliderHomeTwo /> */}
      
      <SliderHomeThree />
      <AboutCompany />
      <Funfact />
      <WhoCan />
      <Courses />
      {/* <Services /> */}
      {/* <TopCategoryHomePageTwo /> */}
      {/* <GalleryHomeTwo /> */}
      {/* <PortfolioThree/> */}
      <Testimonial />
      
      {/* <Brands /> */}
      {/* <BlogHomeTwo /> */}
      {/* <FullScreenBtn /> */}
      <Contact />
      </ScrollSpy>
      <FooterTwo />
    </>
  );
};

export default HomeTwo;
