import React from "react";
// import { Link } from "react-router-dom";

const AboutThree = () => {
  return (
    <>
      {/* <!-- tp-about-3 start  --> */}
      
      <div className="tp-ab-section pt-60 pb-50 color-background" id="whocan">
        <div className="container">
        <div className="row">
            <div className="col-12">
              <div className="tp-section-wrapper text-center mb-30">
                <span className="tp-section-subtitle mb-25">ADMISSION</span>
                <h2 className="tp-section-title" style={{textShadow: ".05em 0 0 rgba(179, 132, 201, .84)"}}>Who All Can Join Us</h2>
              </div>
              <div className="whocan">
            <ol className="whocanol">
            <li className="whocanli">
                  <p class="event-date">Visit Core institute</p>
                  {/* <p class="event-description">HTML Tags, an informal CERN document listing 18 HTML tags, was first mentioned in public.</p> */}
              </li>
              <li className="whocanli">
                  <p class="event-date">Take CoreAptituteTest (CAT)</p>
                  {/* <p class="event-description">HTML Tags, an informal CERN document listing 18 HTML tags, was first mentioned in public.</p> */}
              </li>
              <li className="whocanli">
                  <p class="event-date">Fill Admission Form and Submit Documents</p>
                  {/* <p class="event-description"></p> */}
              </li>
              <li className="whocanli">
                  <p class="event-date">Your journey at CORE INSTITUTE begin</p>
                  {/* <p class="event-description"></p> */}
              </li>
            </ol>
            </div>
            </div>
          </div>
          
          <div className="row align-items-center tp-flex-reverse">
            <div className="col-lg-7">
              <div className="tp-ab-info pr-40">
              <h3 className="mb-5">12<sup>th</sup> Grade+</h3>
              <div style={{backgroundColor:"#662F85", borderRadius:"0 30px 0 0", padding: "2px 10px"}}><h3 style={{color:"white"}}>for all courses</h3></div>
              
              <h3 className="mb-5" style={{textAlign: "right"}}>10<sup>th</sup> Grade+</h3>
              <div style={{backgroundColor:"#662F85",borderRadius:"30px 0 0 0", textAlign: "right", padding: "2px 10px"}}><h3 style={{color:"white"}}>for Short Term courses</h3></div>
              
              <h3 className="mb-5">Young Entrepreneurs</h3>
              <div style={{backgroundColor:"#662F85",borderRadius:"0 30px 0 0", padding: "2px 10px"}}><h3 style={{color:"white"}}>Endeavouring to follow their Passion</h3></div>
              
              <h3 className="mb-5" style={{textAlign: "right"}}>Corporate Proferssional</h3>
              <div style={{backgroundColor:"#662F85",borderRadius:"30px 0 0 0", textAlign: "right", padding: "2px 10px"}}><h3 style={{color:"white"}}>Aspiring for Alternate Careers</h3></div>
                
              <h3 className="mb-5">Home Maker</h3>
              <div style={{backgroundColor:"#662F85",borderRadius:"0 40px 0 0", padding: "5px 10px"}}>
              <h3 style={{color:"white"}}>Enthusiasts that have set their sight on being an Event Manager</h3>
              </div>
               
              
                {/* <div>
                  <Link to="/service" className="tp-btn">
                    All Service
                    <span>
                      <svg
                        width="22"
                        height="8"
                        viewBox="0 0 22 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                      <svg
                        width="22"
                        height="8"
                        viewBox="0 0 22 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </span>
                  </Link>
                </div> */}
              </div>
            </div>
            <div className="col-lg-5">
              <div className="tp-ab-img text-md-end w-img">
                <img src="./assets/img/favicon.png" alt="" />
              </div>
            </div> 
          </div>
        </div>
      </div>
      {/* <!-- tp-about-3 end  --> */}
    </>
  );
};

export default AboutThree;
