import React from "react";
import ReactPlayer from "react-player";
const highlightCont = <h3 style={{color:"#662F85", textAlign:"justify"}}>
Come Transform yourself into a highly-paid expert and a highly-paid 
expert and attain financial freedom, peace of mind, and a career of life.
</h3>;

const AboutCompany = () => {
  return (
    <>
      <div className="about-details-page pt-50 pb-50" id="about">
        <div className="container">
        <div className="row">
            <div className="col-12">
              <div className="tp-section-wrapper text-center mb-30">
                {/* <span className="tp-section-subtitle mb-25">WHY US</span> */}
                <h2 className="tp-section-title" style={{textShadow: ".05em 0 0 rgba(179, 132, 201, .84)"}}>WHY US</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="about-details-info pr-35">
                <div className="tp-section-wrapper">
                  <span className="tp-section-subtitle mb-15">
                    About our Company
                  </span>
                  <h2 className="tp-pt-size mb-15" style={{color:"#ADADAD"}}>
                  <b style={{color:"#662F85"}}>C</b>arving <b style={{color:"#27B2A1"}}>O</b>ut <b style={{color:"#662F85"}}>R</b>eal <b style={{color:"#662F85"}}>E</b>ducation
                  </h2>
                </div>
                <div className="about-tb-content">
                  <nav>
                    <div className="nav mb-15" id="nav-tab" role="tablist">
                      <button
                        className="nav-links active"
                        id="mission"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-mission"
                        type="button"
                        role="tab"
                        aria-controls="nav-mission"
                        aria-selected="true"
                      >
                        Founder’s Message
                      </button>
                      <button
                        className="nav-links"
                        id="nav-core-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-core"
                        type="button"
                        role="tab"
                        aria-controls="nav-core"
                        aria-selected="false"
                      >
                         Core Advantage
                      </button>

                      <button
                        className="nav-links"
                        id="nav-vission-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-vission"
                        type="button"
                        role="tab"
                        aria-controls="nav-vission"
                        aria-selected="false"
                      >
                         What Drives Us
                      </button>
                      <button
                        className="nav-links"
                        id="nav-value-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-value"
                        type="button"
                        role="tab"
                        aria-controls="nav-value"
                        aria-selected="false"
                      >
                         Infrastructure & Facilities
                      </button>
                    </div>
                  </nav>
                  <div className="tab-content" id="nav-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="nav-mission"
                      role="tabpanel"
                      aria-labelledby="nav-mission"
                    >
                      <div className="row">
                        <div className="col-md-7">
                        <p>
                          At Core, we believe that Design is both an art and a science. 
                          Core Institute was founded in 2010 with the aim of creating 
                          top notch professionals who have great technical and commercial 
                          understanding of their profession.
                        </p>

                        <p>
                          With a solid foundation in both the technical and creative 
                          aspects of design, and 100% Placement assistance, our students 
                          pave their paths towards becoming successful professionals 
                          in their fields.
                        </p>
                        <p>
                          A huge Alumini base of nearly 4000 successful professionals proves 
                          testimony to the success of Core Institute.
                        </p>
                        <p>
                          Our faculty is composed of industry experts and experienced educators 
                          who are committed to help our students achieve their goal.
                        </p>
                        {highlightCont}
                        </div>
                        <div className="col-md-5 text-end">
                        {/* <div className="tp-about-img-box p-relative d-inline-block mb-20"> */}
                          {/* <div className="about-page-img"> */}
                          <ReactPlayer
                            url='https://youtu.be/6cA7B8F0poY'
                            playing = {false}
                            width='100%'
                            muted={false}
                            loop={true}
                            controls={true}
                            height='50vh'
                          />
                          {/* </div> */}
                        {/* </div> */}
                      </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="nav-vission"
                      role="tabpanel"
                      aria-labelledby="nav-vission-tab"
                    > 
                    <div className="row">
                        <div className="col-md-9">
                          <p>
                          Welcome to Core Institute, a premier institute offering comprehensive programs in interior 
                          designing, fashion designing, and event management. We take pride in providing top-notch 
                          education and equipping our students with the skills and knowledge they need to excel in 
                          their respective fields. Our institute boasts state-of-the-art infrastructure that supports 
                          a dynamic and immersive learning environment.
                          </p>
                          {highlightCont}
                        </div>
                        <div className="col-md-3 text-end">
                      <div className="tp-about-img-box p-relative d-inline-block mb-20">
                        <div className="about-page-img">
                          <img src="assets/img/about/about1.jpg" alt="" />
                        </div>
                      </div>
                    </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="nav-core"
                      role="tabpanel"
                      aria-labelledby="nav-core-tab"
                    > 
                    <div className="row">
                        <div className="col-md-9">
                          <p>
                          For a decade, Core Institute has remained steadfast in its mission to empower aspiring designers 
                          in turning their dreams into reality. Our institute is dedicated to providing industry-focused 
                          programs, utilizing innovative pedagogy, forging strategic alliances with key industry players, 
                          and facilitating immersive learning experiences. With a strong emphasis on event management, 
                          fashion and interior design, we strive to prepare our students for dynamic realms and lay the 
                          foundation for a thriving career in these fields.
                          </p>
                          {highlightCont}
                        </div>
                        <div className="col-md-3 text-end">
                      <div className="tp-about-img-box p-relative d-inline-block mb-20">
                        <div className="about-page-img">
                          <img src="assets/img/about/about1.jpg" alt="" />
                        </div>
                      </div>
                    </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="nav-value"
                      role="tabpanel"
                      aria-labelledby="nav-value-tab"
                    >
                      <div className="row">
                      <div className="col-md-9">
                      <p>
                        At Core Institute, we believe that a strong infrastructure sets the stage for a successful learning 
                        experience. Our well-equipped facilities, combined with experienced faculty and industry-driven 
                        curriculum, empower our students to unleash their creativity and develop the skills required to thrive 
                        in the competitive fields of interior designing, fashion designing, and event management. Come and 
                        join us to embark on an exciting journey towards a rewarding career in these dynamic industries.
                      </p>
                      <p>
                        Coreinstitute lives with strong values by which we are committed to provide:
                        <ul>
                          <li> ✔ Ultra Modern Infrastructure</li>
                          <li> ✔ Upbeat Classroom</li>
                          <li> ✔ High Tech Labs</li>
                          <li> ✔ Placement and Counselling Cell</li>
                          <li> ✔ Industry Vists and Expert Sessions</li>
                          <li> ✔ Government Certified Degree, Diploma and Short Term Courses</li>
                        </ul>
                      </p>
                      
                      {highlightCont}
                      </div>
                      <div className="col-md-3 text-end">
                      <div className="tp-about-img-box p-relative d-inline-block mb-20">
                        <div className="about-page-img">
                          <img src="assets/img/about/about-page.png" alt="" />
                        </div>
                      </div>
                    </div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-4 text-end">
              <div className="tp-about-img-box p-relative d-inline-block mb-20">
                <div className="about-page-img">
                  <img src="assets/img/about/about1.jpg" alt="" />
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutCompany;
