import React from "react";
import { useRef } from "react";
import Slider from "react-slick";
import testimonialData from "../../data/TestimonialData.json";

const settings = {
  dots: false,
  infinite: true,
  speed: 300,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        arrows: false,
      },
    },
  ],
};

const Testimonial = () => {
  const sliderRef = useRef(null);
  return (
    <>
      {/* <!-- testimonial slider start  --> */}
      <div className="testimonial color-background"  >
        <div className="tp-testimonial-area pt-60 pb-50">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
              <div className="tp-section-wrapper text-center mb-30">
                <span className="tp-section-subtitle mb-25">TESTIMONIALS</span>
                <h2 className="tp-section-title" style={{textShadow: ".05em 0 0 rgba(179, 132, 201, .84)"}}>What our Client say</h2>
              </div>
                <div
                  className="tp-testimonial"
                  // style={{
                  //   backgroundImage: `url(/assets/img/bg/testimonial.jpg)`,
                  // }}
                >
                  <div
                    className={`tp-testimonial-active p-relative`}
                  >
                    <button
                      onClick={() => sliderRef.current?.slickPrev()}
                      type="button"
                      className="carousel-control-prev"
                      // className="slick-prev slick-arrow d-none d-lg-block"
                    >
                      <span className="test">
                        <i className="fal fa-angle-left"></i>
                      </span>
                    </button>
                    <Slider ref={sliderRef} {...settings}>
                      {testimonialData.testimonialData.map((item) => (
                        <div key={item.id} className="tp-testimonial-item">
                          <div className="tp-section-title-sm-box text-center">
                            <div className="tp-testi-reivew">
                              {/* <h2>{item.title}</h2> */}
                              <h4 className="carousel-caption">
                                {item.text1}
                                {item.text2}
                                {item.text3}
                              </h4>
                              {/* <img src={item.img} alt="testimonial"/> */}
                              <div className="tp-testi-meta">
                                <h3 className="tp-testi-reviewer">
                                  {item.reviewer}
                                </h3>
                                <span>{item.design}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </Slider>
                    <button
                      onClick={() => sliderRef.current?.slickNext()}
                      type="button"
                      className="carousel-control-next test"
                      // className="slick-next slick-arrow d-none d-lg-block"
                    >
                      <i className="fal fa-angle-right"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- testimonial slider end  --> */}
    </>
  );
};

export default Testimonial;
