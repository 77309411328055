import React from "react";
import FooterTwo from "../../common/Footer/FooterTwo";
import Menu from "../../common/Menu/Menu";
import ContactInfo from "./Enquiry";
import ContactUsBanner from "./EnquiryBanner";

const ContactUs = () => {
  return (
    <>
      <Menu header_style_2={true} />
      {/* <ContactUsBanner /> */}
      <ContactInfo />
      <FooterTwo />
    </>
  );
};

export default ContactUs;
