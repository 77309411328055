import React from "react";
import FooterTwo from "../../common/Footer/FooterTwo";
import Menu from "../../common/Menu/Menu";
// import ContactWithFashion from "./ContactWithFashion";
import SingleFashionBanner from "./SingleFashionBanner";
import FashionDetailsAbout from "./FashionDetailsAbout";
// import FashionDetailsHero from "./FashionDetailsHero";

const FashionDetails = () => {
  return (
    <>
      <Menu header_style_2={true} />
      <SingleFashionBanner />
      {/* <FashionDetailsHero /> */}
      <FashionDetailsAbout />
      {/* <ContactWithFashion /> */}
      <FooterTwo />
    </>
  );
};

export default FashionDetails;
